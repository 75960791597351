/**
 * OVERRIDE NewsItemView.jsx
 * REASON: Adding dates and headtitle above the title of page.
 * DATE: 2023-07-04
 * DEVELOPER: @IFlameing
 */

import NewsItemView from '../../../../../components/Theme/NewsItemView';

export default NewsItemView;
