/**
 * OVERRIDE FileView.jsx
 * REASON: Adding size of file.
 * DATE: 2023-07-11
 * DEVELOPER: @iRohitSingh
 */

import FileView from '../../../../../components/Theme/FileView';

export default FileView;
