/**
 * OVERRIDE Anontools.jsx
 * REASON: This theme uses a custom pre-@plone/components component
 * SemanticUI-free located at the components folder.
 * To override it, override the @kitconcept/volto-light-theme one instead of
 * this one.
 */

import Anontools from '../../../../../components/Anontools/Anontools';

export default Anontools;
